<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>
                Administration des tâches programmées
            </h1>
            <b-button
                size="sm"
                variant="outline-kalkin-2"
                v-b-modal.ModalCreateCronjob
            >
                <b-icon-clock />
                Nouvelle programmation
            </b-button>
        </div>

        <div class="k-page-body text-left">
            <TableCronjobs
                id="table-cronjobs"
                perPage="9"
                @select="onUpdateCronjob"
            />
        </div>

        <ModalCreateCronjob @saved="refreshCronjobs" />

        <ModalUpdateCronjob
            :cronjob="selectedCronjob"
            @saved="refreshCronjobs"
        />
    </div>
</template>

<script>
import TableCronjobs from "@/components/model/cronjob/TableCronjobs.vue";
import ModalCreateCronjob from "@/components/model/cronjob/ModalCreateCronjob.vue";
import ModalUpdateCronjob from "@/components/model/cronjob/ModalUpdateCronjob.vue";
export default {
    components: {
        TableCronjobs,
        ModalCreateCronjob,
        ModalUpdateCronjob,
    },

    data: function() {
        return {
            selectedCronjob: {},
        };
    },

    methods: {
        onUpdateCronjob(job) {
            this.selectedCronjob = job;
            this.$bvModal.show("ModalUpdateCronjob");
        },

        refreshCronjobs() {
            this.$root.$emit("bv::refresh::table", "table-cronjobs");
        },
    },
};
</script>
