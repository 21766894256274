<template>
    <div class="k-flex-col">
        <div class="k-flex-row">
            <div class="flex-grow-1 mr-4">
                <b-input-group>
                    <template #prepend>
                        <b-input-group-text>
                            <b-icon-search />
                        </b-input-group-text>
                    </template>
                    <b-form-input
                        placeholder="Rechercher une tâche"
                        v-model="filter"
                        debounce="500"
                    />
                </b-input-group>
            </div>

            <div class="d-flex flex-column">
                <small class="text-kalkin-1 text-center w-100">
                    tâches programmées par page
                </small>
                <b-form-group>
                    <b-form-radio-group
                        v-model="perPage"
                        size="sm"
                        button-variant="outline-kalkin-1"
                        buttons
                    >
                        <b-form-radio :value="5">5</b-form-radio>
                        <b-form-radio :value="10">10</b-form-radio>
                        <b-form-radio :value="20">20</b-form-radio>
                        <b-form-radio :value="50">50</b-form-radio>
                        <b-form-radio :value="100">100</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
        </div>
        <b-table
            :id="id"
            class="text-break mt-2 text-center"
            :items="dataProvider"
            :fields="fields"
            :current-page="currentPage"
            :filter="filter"
            :per-page="perPage"
            sort-by="createdAt"
            show-empty
            empty-text="Aucune tâche programmée"
            empty-filtered-text="Aucune tâche programmée trouvée"
            stacked="md"
            hover
            tbody-tr-class="pointer"
            @row-clicked="onCronjobSelected"
            striped
        >
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Chargement des données...</strong>
                </div>
            </template>
            <template #cell(createdAt)="data">
                {{ data.item.createdAt | dateFromISO(true) }}
            </template>
            <template #cell(name)="data">
                <div class="text-kalkin-1 font-weight-bold">
                    {{ data.item.projectName || data.item.name }}
                </div>
                <small>{{
                    data.item.configName || data.item.description
                }}</small>
            </template>
            <template #cell(enabled)="data">
                <div class="text-kalkin-2" v-if="data.item.enabled">
                    <b-icon-check-circle /> <small class="d-block">Actif</small>
                </div>
                <div class="text-danger" v-else>
                    <b-icon-x-circle />
                    <small class="d-block">Désactivé</small>
                </div>
            </template>
            <template #cell(time)="data">
                <div class="text-monospace">
                    {{ data.item.time }}
                </div>
                <small>{{ data.item.timeHuman }}</small>
            </template>
        </b-table>

        <div>
            <b-pagination
                class="m-auto pb-2"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalRows"
                align="center"
                size="sm"
                first-number
                last-number
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: { type: String, required: true },
    },

    data: function() {
        return {
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            fields: [
                {
                    key: "id",
                    label: "Identifiant",
                    sortable: true,
                    tdClass: "",
                },
                {
                    key: "name",
                    label: "Informations",
                    sortable: true,
                    tdClass: "text-left",
                },
                {
                    key: "enabled",
                    label: "État",
                    sortable: true,
                    tdClass: "text-center",
                },
                {
                    key: "time",
                    label: "Programmation",
                    tdClass: "text-left",
                },
                {
                    key: "action",
                    label: "Commande",
                    tdClass: "text-left text-monospace",
                },
                {
                    key: "createdAt",
                    label: "Création",
                    sortable: true,
                },
            ],
            filter: "",
        };
    },

    watch: {
        async filter() {
            await this.fetchTotalRows();
        },
    },

    methods: {
        async fetchTotalRows() {
            this.totalRows = await this.$store.dispatch(
                "cronjob/countFSPCronjobs",
                {
                    filter: `name substring ${this.filter} or description substring ${this.filter} or action substring ${this.filter}`,
                },
            );
        },

        async dataProvider(ctx) {
            const cronjobs = await this.$store.dispatch(
                "cronjob/getFSPCronjobs",
                {
                    filter: `name substring ${this.filter} or description substring ${this.filter} or action substring ${this.filter}`,
                    sortby: (ctx.sortDesc ? "-" : "") + ctx.sortBy,
                    limit: this.perPage,
                    offset: this.perPage * (this.currentPage - 1),
                },
            );
            return cronjobs;
        },

        onCronjobSelected(job) {
            this.$emit("select", job);
        },
    },

    async beforeMount() {
        await this.fetchTotalRows();
    },
};
</script>
