<template>
    <div>
        <div>
            <b-form-group>
                <label>
                    <b>Nom de la tâche</b> <span class="text-danger">*</span>
                </label>
                <b-form-input
                    v-model="name"
                    :state="isNameValid"
                    trim
                ></b-form-input>
                <b-form-invalid-feedback :state="isNameValid">
                    Veuillez saisir le nom de la tâche
                </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group>
                <label>
                    <b>Description</b>
                </label>
                <b-form-input v-model="description" trim></b-form-input>
            </b-form-group>

            <b-form-group>
                <label>
                    <b>Programmation</b> <span class="text-danger">*</span>
                </label>
                <b-form-input
                    class="text-monospace"
                    v-model="time"
                    :state="isTimeValid"
                    trim
                ></b-form-input>
                <b-form-invalid-feedback :state="isTimeValid">
                    Veuillez saisir une programmation valide
                </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group>
                <label>
                    <b>Commande</b> <span class="text-danger">*</span>
                </label>
                <b-form-input
                    class="text-monospace"
                    v-model="action"
                    :state="isActionValid"
                    trim
                ></b-form-input>
                <b-form-invalid-feedback :state="isActionValid">
                    Veuillez saisir une commande valide
                </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group>
                <label>
                    <b>Activer la programmation</b>
                </label>
                <b-form-checkbox class="k-switch" v-model="enabled" switch>
                    <span v-show="enabled">Activé</span>
                    <span v-show="!enabled">Désactivé</span>
                </b-form-checkbox>
            </b-form-group>
        </div>

        <div
            class="k-flex-row text-danger my-3"
            v-if="isEditMode && cronjob.projectName"
        >
            <div><b-icon-exclamation-circle font-scale="2" /></div>
            <div class="font-italic ml-2">
                Attention, cette tâche a été générée automatiquement
                <br />Modifier son nom, sa description ou sa commande pourrait
                créer un dysfonctionnement
            </div>
            <div class="flex-grow-1" />
        </div>

        <div class="k-flex-row k-flex-center">
            <b-button
                v-if="!isEditMode"
                variant="kalkin-2"
                @click="createCronjob()"
            >
                <b-icon-clock /> Créer la tâche programmée
            </b-button>
            <b-button
                v-if="isEditMode"
                variant="outline-danger"
                v-b-modal.ModalDanger
            >
                <b-icon-trash /> Supprimer la tâche programmée
            </b-button>
            <b-button
                v-if="isEditMode"
                variant="kalkin-2"
                @click="updateCronjob()"
            >
                <b-icon-check-circle-fill /> Enregistrer les modifications
            </b-button>
        </div>
        <ModalDanger
            message="Voulez-vous vraiment supprimer cette tâche?"
            @confirmed="deleteCronjob"
        />
    </div>
</template>
<script>
import ModalDanger from "@/components/modal/ModalDanger";
export default {
    components: {
        ModalDanger,
    },

    props: {
        cronjob: { type: Object, required: false },
    },

    data: function() {
        if (this.cronjob) {
            return {
                isEditMode: true,
                name: this.cronjob.name,
                description: this.cronjob.description,
                time: this.cronjob.time,
                action: this.cronjob.action,
                enabled: !!this.cronjob.enabled,
            };
        } else {
            return {
                isEditMode: false,
                name: null,
                description: null,
                time: null,
                action: null,
                enabled: false,
            };
        }
    },

    computed: {
        isNameValid() {
            if (this.name === null) {
                return null;
            } else {
                return this.name !== "";
            }
        },

        isTimeValid() {
            if (this.time === null) {
                return null;
            } else {
                return this.time !== "";
            }
        },

        isActionValid() {
            if (this.action === null) {
                return null;
            } else {
                return this.action !== "";
            }
        },

        isValid() {
            return this.isNameValid && this.isTimeValid && this.isActionValid;
        },
    },

    methods: {
        createCronjob: async function() {
            if (this.isValid) {
                await this.$store.dispatch("cronjob/createCronjob", {
                    name: this.name,
                    description: this.description,
                    enabled: this.enabled,
                    time: this.time,
                    action: this.action,
                });
                this.$emit("saved");
            } else {
                this.name = this.name || "";
                this.time = this.time || "";
                this.action = this.action || "";
            }
        },

        updateCronjob: async function() {
            if (this.isValid) {
                await this.$store.dispatch("cronjob/updateCronjob", {
                    id: this.cronjob.id,
                    name: this.name,
                    description: this.description,
                    enabled: this.enabled,
                    time: this.time,
                    action: this.action,
                });
                this.$emit("saved");
            }
        },

        deleteCronjob: async function() {
            await this.$store.dispatch("cronjob/deleteCronjob", {
                id: this.cronjob.id,
            });
            this.$emit("saved");
        },
    },
};
</script>
